import React, { useEffect } from "react";
import styled from "styled-components";
import { SuccessSubmission, FailSubmission, Disclaimer } from "./ModalMessage";
import { CrossX } from "../../assets/IconSVG";
import QuantityDateSelector from "./QuantityDateSelector";

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: ${props => props.$isBottomAligned ? 'flex-end' : 'center'};
  align-items: ${props => props.$isBottomAligned ? 'flex-end' : 'center'};
  z-index: 999999;
  pointer-events: auto;
  touch-action: none;
`;

export const ModalContent = styled.div`
  background: white;
  padding: ${props => props.$isBottomAligned ? '20px' : '30px'};
  border-radius: ${props => props.$isBottomAligned ? '10px 10px 0 0' : '10px'};
  width: 100%;
  max-width: 500px;
  position: relative;
  margin: 0 auto;
  max-height: 90vh;
  // overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  pointer-events: auto;
  touch-action: pan-y;
  overflow: hidden;
  
  
`;

export const CloseContainer = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const MessageModal = ({
  isOpen,
  setModalOpen,
  formData,
  setFormData,
  autoClose,
  template,
  onConfirm,
  onCancel,
  baseFee,
  classDates,
  content,
  language,
  maxQuantity
}) => {
  const isBottomAligned = template === "quantityDateSelector";

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const toggleModal = () => {
    setModalOpen(!isOpen);
  };

  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      e.preventDefault();
      e.stopPropagation();
      toggleModal();
    }
  };

  return (
    <ModalBackground 
      $isBottomAligned={isBottomAligned}
      onClick={handleBackgroundClick}
      onTouchEnd={handleBackgroundClick}
    >
      <ModalContent 
        $isBottomAligned={isBottomAligned}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseContainer>
          <CrossX onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleModal();
          }} />
        </CloseContainer>
        {template === "quantityDateSelector" && (
          <QuantityDateSelector
            onConfirm={onConfirm}
            onCancel={onCancel}
            baseFee={baseFee}
            classDates={classDates}
            maxQuantity={maxQuantity}
          />
        )}
        {template === "success" && <SuccessSubmission language={language} />}
        {template === "fail" && <FailSubmission language={language} />}
        {template === "disclaimerConsent" && (
          <Disclaimer
            language={language}
            setModalOpen={setModalOpen}
            formData={formData}
            setFormData={setFormData}
            modalOpen={isOpen}
            content={content}
          />
        )}
      </ModalContent>
    </ModalBackground>
  );
};

export default MessageModal;
