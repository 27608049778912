import React, { useState } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';

const Container = styled.div`
  padding: 20px 20px 80px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 90vh;
  // // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  // border:1px solid red;
`;

const Header = styled.h3`
  margin-bottom: 20px;
  flex-shrink: 0;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-shrink: 0;
`;

const QuantityButton = styled.button`
  width: 30px;
  height: 30px;
  margin: 0 10px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  background-color: #f0f0f0;
  border-radius: 5px;
`;

const DateList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-right: 10px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
`;

const DateItem = styled.li`
  margin-bottom: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
`;

const DateLabel = styled.span`
  margin-left: 10px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-top: 1px solid #ddd;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const PriceDisplay = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const ConfirmButton = styled.button`
  background-color: orange;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  pointer-events: auto;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;

  &:active {
    background-color: darkorange;
  }
`;

const Notice = styled.div`
  color: red;
  margin-top: 10px;
  font-size: 14px;
  flex-shrink: 0;
`;

const QuantityDateSelector = ({ classDates, onConfirm, baseFee, maxQuantity }) => {
  const [quantity, setQuantity] = useState(1);
  const [selectedDates, setSelectedDates] = useState([]);


  const activeCourse = JSON.parse(Cookies.get('activeCourse') || '{}');
  console.log(activeCourse)

  const handleQuantityChange = (change) => {
    setQuantity((prev) => {
      const newQuantity = Math.max(1, Math.min(prev + change, maxQuantity));
      if (selectedDates.length > newQuantity) {
        setSelectedDates(selectedDates.slice(0, newQuantity));
      }
      return newQuantity;
    });
  };

  const handleDateChange = (date) => {
    setSelectedDates((prevDates) =>
      prevDates.includes(date)
        ? prevDates.filter((d) => d !== date)
        : [...prevDates, date]
    );
  };

  const handleConfirm = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    if (selectedDates.length === quantity) {
      // Sort the selected dates and get their corresponding names and IDs
      const selectedClassDates = selectedDates.map(date => {
        const classDate = classDates.find(cd => cd.date === date);
        return {
          date: date,
          name: classDate.name,
          id: classDate.id
        };
      }).sort((a, b) => new Date(a.date) - new Date(b.date));
      
      // Get the existing activeCourse cookie
      const activeCourse = JSON.parse(Cookies.get('activeCourse') || '{}');
      
      // Update the cookie with the new data
      Cookies.set('activeCourse', JSON.stringify({
        ...activeCourse,
        quantity,
        selectedDates: selectedClassDates,
        selectedClassIds: selectedClassDates.map(date => date.id),
        totalPrice: baseFee * quantity
      }));

      onConfirm({ 
        quantity, 
        selectedDates: selectedClassDates,
        selectedClassIds: selectedClassDates.map(date => date.id),
        totalPrice: baseFee * quantity
      });
    }
  };

  const totalPrice = baseFee * quantity;

  // Sort classDates by date
  const sortedClassDates = [...classDates].sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <Header>Select Quantity and Dates</Header>
      <QuantityContainer>
        <span>Quantity:</span>
        <QuantityButton onClick={(e) => {
          e.stopPropagation();
          handleQuantityChange(-1);
        }}>-</QuantityButton>
        <span>{quantity}</span>
        <QuantityButton onClick={(e) => {
          e.stopPropagation();
          handleQuantityChange(1);
        }}>+</QuantityButton>
      </QuantityContainer>
      {quantity === maxQuantity && (
        <Notice>Max quantity has been reached</Notice>
      )}
      <DateList>
        {sortedClassDates.map((classDate) => (
          <DateItem key={classDate.id}>
            <input
              type="checkbox"
              value={classDate.date}
              checked={selectedDates.includes(classDate.date)}
              onChange={(e) => {
                e.stopPropagation();
                handleDateChange(classDate.date);
              }}
              disabled={!selectedDates.includes(classDate.date) && selectedDates.length >= quantity}
            />
            <DateLabel>
              {new Date(classDate.date).toLocaleDateString()} - {classDate.name}
            </DateLabel>
          </DateItem>
        ))}
      </DateList>
      {selectedDates.length === 0 || selectedDates.length !== quantity ? (
        <Notice>Please select {quantity} date{quantity > 1 ? 's' : ''}</Notice>
      ) : null}
      <Footer>
        <PriceDisplay>HK$ {totalPrice}</PriceDisplay>
        {selectedDates.length === quantity ? (
          <ConfirmButton 
            onTouchEnd={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleConfirm();
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleConfirm();
            }}
          >
            Book Now
          </ConfirmButton>
        ) : (
          <ConfirmButton disabled>
            Select {quantity} date{quantity > 1 ? 's' : ''}
          </ConfirmButton>
        )}
      </Footer>
    </Container>
  );
};

export default QuantityDateSelector;