import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  LogintemplateRoot,
  HeaderTitle,
  InputForm,
  InputBoxWrapper,
  InputBox,
  Message,
  TitleContainer,
  FormContainer,
  InputContainer,
  Input,
  SubmitButton,
  FlexWrapper,
  DisclaimerWrapper,
  FormFeedback,
} from "./LoginStyles";
import styled from "styled-components";
import ButtonMobile from "../../components/ButtonMobile/ButtonMobile";
import ButtonBack from "../../components/ButtonMobile/ButtonBack";
import MobileInput from "./MobileInput"; // Import the new MobileInput component

import { AuthContext } from "../../context/authContext";

import api from "../../Api"; // adjust the path based on where api.js is located
import Cookies from "js-cookie";

const Login = () => {
  // In your Login component
  const { refreshUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    mobile: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const navigate = useNavigate();

  // Regular expression for email validation
  const emailRegex = /\S+@\S+\.\S+/;
  // Regular expression for password validation
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const mobileRegex = /^\d{8}$/;

  const validateForm = () => {
    const errors = {};

    if (!formData.mobile) {
      errors.mobile = "Mobile is required";
    } else if (!mobileRegex.test(formData.mobile)) {
      errors.mobile =
        "Mobile number has to be in 8 digits long and only numbers";
    }

    // if (!formData.email) {
    // 	errors.email = "Email is required";
    // } else if (!emailRegex.test(formData.email)) {
    // 	errors.email = "Invalid email format";
    // }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (!passwordRegex.test(formData.password)) {
      errors.password =
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Valid form if no errors/ return true or false
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  /******************************************* */
  //INSERTED BY RICK CHU NEW CODE FOR MOBILE
  const handleMobileChange = (mobileArray) => {
    // Convert the array of digits to a string and update formData.mobile
    const mobileString = mobileArray.join("");
    setFormData((prevFormData) => ({
      ...prevFormData,
      mobile: mobileString,
    }));
  };

  const handleSubmit = async (e) => {
    const isValid = validateForm();

    if (isValid) {
      try {
        const res = await api.post("/classBooking/api/auth/login", formData, {
          withCredentials: true,
        });

        if (res.status !== 200) {
          console.log("error");
          setFormErrors(res.data);
        } else {
          refreshUser(); // Refresh the user context
          
          // Check for activeCourse cookie
          const activeCourseCookie = Cookies.get('activeCourse');
          if (activeCourseCookie) {
            navigate('/en/classconfirmpayment');
          } else {
            navigate('/en/');
          }
        }
      } catch (err) {
        console.log(err.response.data);
        setFormErrors(err.response.data);
        console.log(formErrors);
      }
    } else {
      console.log("Form is invalid! Not submitting.", formErrors);
    }
  };

  return (
    <LogintemplateRoot>
      {/* <ButtonBack loading="lazy" alt="ArrowBack" /> */}
      <HeaderTitle>Log In</HeaderTitle>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {/* /******************************************* *
        INSERTED BY RICK CHU  NEW CODE FOR MOBILE 
        */}
        <MobileInput
          mobileValue={formData.mobile}
          onMobileChange={handleMobileChange}
        />
        {formErrors.mobile && <FormFeedback>{formErrors.mobile}</FormFeedback>}

        {/* OLD CODE INPUT FOR MOBILE: */}
        {/* <Input
          required
          type="phone"
          placeholder="Mobile"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
        /> 
        {formErrors.mobile && <FormFeedback>{formErrors.mobile}</FormFeedback>}
        */}
        <Input
          required
          type="text"
          placeholder="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
        {formErrors.password && (
          <FormFeedback>{formErrors.password}</FormFeedback>
        )}
        <ButtonMobile onClick={handleSubmit}>Login</ButtonMobile>
        {/* {error && <p>{error}</p>} */}
      </FormContainer>
      <FlexWrapper>
        <div>
          <Link to="/en/signup">Sign Up </Link>
        </div>
        <div>
          <Link to="/en/resetpassword">Forgot password</Link>
        </div>
      </FlexWrapper>
      <DisclaimerWrapper>
        By continuing, you agree to Dynamic's Privacy Policy and Terms of
        Service
      </DisclaimerWrapper>
      <DisclaimerWrapper>
        © 2002 - {new Date().getFullYear()} DYNAMIC MANAGEMENT GROUP LIMITED.
        <br /> ALL RIGHTS RESERVED.
      </DisclaimerWrapper>
    </LogintemplateRoot>
  );
};

export default Login;
